var Titan = {
    /**
     * TitanConfig
     *
     * @see master.blade.php
     */
    Config: null,

    /**
     * Helpers
     */
    Helpers: {
        /**
         * Toastr notify
         *
         * @param type
         * @param message
         * @returns {boolean}
         */
        notify: function (type, message) {

            toastr.clear();

            toastr.options = {
                "closeButton": true,
                "debug": false,
                "progressBar": true,
                "positionClass": "toast-top-right",
                "timeOut": "10000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };

            toastr[type](message);

            return true;
        },

        /**
         * Dom trigger
         *
         * @param element
         */
        trigger: function (selector) {
            $(selector).click();
        },

        /**
         * Dom js element call
         *
         * @param method
         * @param response
         */
        call: function (method, response) {
            window[method](response);
        },

        /**
         * Redirect page
         *
         * @param url
         * @param message
         */
        redirect: function (url, message) {
            Titan.Dimmer.page(message);

            setTimeout(function () {
                window.location = url;
            }, 1000);
        },

        /**
         * Json parser
         *
         * @param string
         * @returns {any}
         */
        textToJson: function (string) {
            return JSON.parse(string);
        },

        /**
         * Animation destroy dom element
         *
         * @param id
         */
        destroy: function (id) {

            $(id).fadeOut('slow', function () {
                $(this).remove();
            });

            id = id.replace('#', '');

            $('*[data-id="' + id + '"]').fadeOut('slow', function () {
                $('*[data-id="' + id + '"]').remove();
            });
        },

        /**
         * Default image
         *
         * @param selector
         * @param image
         */
        imageError: function (selector, image) {
            $(selector).attr('src', image ? image : Titan.Config.images.noImage);
        },

        /**
         * Load request page
         *
         * @param selector
         * @param url
         */
        load: function (selector, url) {
            Titan.Dimmer.page();

            $(selector).load(url, function (response, status, xhr) {
                Titan.Dimmer.destroy();
                Titan.Fire();

                if (status == 'error') {
                    Titan.Helpers.notify('error', Titan.Config.languages.pageNotFound);

                    return false;
                }

                return true;
            });
        }
    },

    /**
     * Currency process
     */
    Currency: {

        /**
         * Turkish money format
         *
         * @param price
         * @returns {*|*}
         */
        format: function (price) {
            return accounting.formatMoney(price, {
                symbol: '₺',
                format: '%v%s',
                precision: 2,
                decimal: ',',
                thousand: '.'
            });
        }
    },

    /**
     * Form process
     */
    Form: {

        /**
         * Disable form action
         *
         * @param selector
         */
        disable: function (selector) {
            $(selector).find('.button-submit').addClass('disabled');

            $(selector).append('<div class="ui active inverted dimmer"><div class="ui text loader"></div></div>');
        },

        /**
         * Enable form action
         *
         * @param selector
         */
        enable: function (selector) {
            $(selector).find('.button-submit').removeClass('disabled');
            $(selector).find('.ui.inverted').remove();
        },

        /**
         * Form validation check
         *
         * @param selector
         * @param rules
         */
        validation: function (selector, rules) {

            $(selector).validate({
                rules: rules,
                errorClass: 'has-error',
                errorElement: 'span',
                focusCleanup: false,
                debug: false,
                submitHandler: function (selector) {
                    if ($(selector).find('.button-submit').hasClass('disabled')) {
                        return false;
                    }

                    Titan.Form.sender(selector);

                    return false;
                }
            });
        },

        /**
         * Form send
         *
         * @param selector
         * @returns {boolean}
         */
        sender: function (selector) {

            toastr.clear();

            var form = $(selector);

            Titan.Form.disable(selector);

            form.ajaxSubmit({
                url: form.data('action'),
                type: form.data('method') ? form.data('method') : 'POST',
                dataType: 'json',
                data: $(form.data('forms')).serializeArray(),
                success: function (response) {

                    Titan.Form.enable(selector);
                    Titan.Form.responseSuccess(response);

                    return false;
                },
                error: function (response) {

                    Titan.Form.enable(selector);
                    Titan.Form.responseError(response);

                    return false;
                }
            });

            return false;
        },

        /**
         * Form success process
         *
         * @param response
         * @returns {*}
         */
        responseSuccess: function (response) {

            if (response.success) {
                Titan.Helpers.notify('success', response.success);
            }

            if (response.removed) {
                Titan.Form.destroy(response.removed);
            }

            if (response.triggers) {
                Titan.Form.triggers(response.triggers);
            }

            if (response.call) {
                Titan.Form.calls(response);
            }

            if (response.redirect) {
                return Titan.Helpers.redirect(response.redirect.url, response.redirect.message);
            }

            return true;
        },

        /**
         * Form error process
         *
         * @param response
         * @returns {*}
         */
        responseError: function (response) {

            var status = response.status;

            if (response.responseText) {
                response = Titan.Helpers.textToJson(response.responseText);
            }

            if (response.removed) {
                Titan.Form.destroy(response.removed);
            }

            if (response.triggers) {
                Titan.Form.triggers(response.triggers);
            }

            if (response.call) {
                Titan.Form.calls(response);
            }

            if (response.errors) {
                $.each(response.errors, function (index, el) {
                    Titan.Helpers.notify('error', el[0]);

                    return false;
                });
            }

            if (response.error) {
                Titan.Helpers.notify('error', response.error);
            }

            if (!response.error && status == 401) {

                var json = Titan.Helpers.textToJson(response.responseText);

                Titan.Helpers.notify('error', json.message, json.title);
            }

            if (!response.error && status == 404) {
                Titan.Helpers.notify('error', Titan.Config.languages.pageNotFound);
            }

            if (!response.error && status == 405) {
                Titan.Helpers.notify('error', Titan.Config.languages.methodNotAllowed);
            }

            if ((!response.error && status == 500) || (!response.error && status == 502)) {
                Titan.Helpers.notify('error', Titan.Config.languages.criticalError);
            }

            if (response.redirect) {
                return Titan.Helpers.redirect(response.redirect.url, response.redirect.message);
            }

            return false;
        },

        /**
         * Response data destroy
         *
         * @param items
         * @returns {boolean}
         */
        destroy: function (items) {
            $.each(items, function (index, element) {
                Titan.Helpers.destroy(element);
            });

            return true;
        },

        /**
         * Click response elements
         *
         * @param items
         * @returns {boolean}
         */
        triggers: function (items) {
            $.each(items, function (index, element) {
                Titan.Helpers.trigger(element);
            });

            return true;
        },

        /**
         * Call dom script
         *
         * @param response
         * @returns {boolean}
         */
        calls: function (response) {
            $.each(response.call, function (index, element) {
                Titan.Helpers.call(element, response);
            });

            return true;
        }
    },

    /**
     * Dimmer
     */
    Dimmer: {

        pageSelector: '#PageDimmer',

        selector: '#dimmer',

        page: function (message) {
            $(Titan.Dimmer.pageSelector).empty().append(Titan.Dimmer.create(message));
        },

        create: function (message) {

            if (!message) {
                message = Titan.Config.languages.pageLoad;
            }

            return '<div id="dimmer" class="ui active inverted dimmer"><div class="ui large text loader">' + message + '</div></div>';
        },

        destroy: function () {
            $(Titan.Dimmer.selector).remove();
        }
    },

    /**
     * Search
     */
    Search: {
        fill: function (field) {
            field.find('.form__select').on("select2:select", function (e) {
                Titan.Dimmer.page();

                var params = field.data();
                var fill = $(params.fill);

                $.each((params.fills).split(', '), function (key, paramFill) {
                    $(paramFill).find('.form__select').val(null).trigger('change');
                    $(paramFill).find('.form__select').empty().trigger("change");
                });

                $.getJSON(params.uri, {parent: e.params.data.id}, function (response) {

                    $.each(response.items, function (key, item) {
                        fill.find('.form__select').append((new Option(item.title, item.id, false, false))).trigger('change');
                    });

                    Titan.Dimmer.destroy();
                });
            });
        },

        fire: function () {
            $('.field.fill').each(function (key, element) {
                Titan.Search.fill($(element));
            })
        }
    },

    /**
     * Modal
     */
    Modal: {

        /**
         * Open selector
         *
         * @param selector
         */
        open: function (selector) {
            $('.js-modal-target').hide();
            $(selector).show();

            $('.js-overlay').addClass('active');
            $('.js-modal').fadeIn().addClass('active');
        },

        /**
         * Swal modal approve
         *
         * @param selector
         */
        approve: function (selector) {
            swal({
                title: 'Eminmisiniz?',
                text: 'Eğer bu işlemi onaylarsanız hiç bir sekilde geri alamazsınız!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sil',
                cancelButtonText: 'İptal',
            }).then(function (result) {
                if (!result.value) {
                    return true;
                }

                Titan.Form.sender(selector);
            });
        }
    },

    /**
     * Ui process
     */
    Ui: {
        select2: function () {
            $('.js-select-search, .form__select').select2();
            $('.form__select__tags').select2({
                tags: true,
                minimumResultsForSearch: -1,
                // dropdownParent: $parent,
                width: '100%'
            });
        },

        datepicker: function () {
            $('.js-datepicker').datepicker({
                autoHide: true,
                days: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                daysShort: ['Pzr', 'Pzt', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cmt'],
                daysMin: ['Pz', 'Pt', 'Sa', 'Çr', 'Pr', 'Cu', 'Ct'],
                months: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                monthsShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kası', 'Ara'],
                weekStart: 1,
                format: 'dd/mm/yyyy'

            });
        },

        featuredSlider: function () {
            return new Swiper('.js-text-slider-fade', {
                effect: 'fade',
                grabCursor: true,
                loop: true,
                centeredSlides: false,
                keyboard: true,
                spaceBetween: 0,
                slidesPerView: 1,
                speed: 500,
                navigation: {
                    nextEl: '.js-text-slider-next',
                    prevEl: '.js-text-slider-prev',
                },
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false
                },
                breakpoints: {
                    576: {
                        // slidesPerView: 1,
                    },
                    900: {
                        // slidesPerView: 2,
                    },
                    1200: {
                        // slidesPerView: 3,
                    }
                },
                simulateTouch: true,
                pagination: {
                    el: '.js-text-slider-pagination',
                    clickable: true
                },
                on: {
                    init: function () {
                        // $('.market-slider__item-wrp').removeClass('swiper-slide-hide');
                        // $('.market-slider__item-wrp.swiper-slide-prev').addClass('swiper-slide-hide').prev().addClass('swiper-slide-hide');
                        // var count = this.slides.length;
                    },
                }
            });
        },

        autocomplete: function (input) {

            var items = [];

            $(input).on('focus', function (e) {

                if (items) {
                    $('.js-overlay').addClass('active');
                    $('.js-search').addClass('active');
                    $('.js-search-category').removeClass('active');
                }

            }).on('focusout', function () {
                if (!$(this).val() == "" || !$(this).val() == " ") {
                    $(this).addClass('active');
                } else {
                    $(this).removeClass('active');
                }
            });

            $(input).on('keyup', function () {

            });
        },

        mask: function () {
            $('.phone').mask('0 (000) 000 00 00');
            $('.cc-number').mask('0000 0000 0000 0000');
            $('.cc-cvv').mask('000');
            $('.identification-no').mask('00000000000');
        },

        headerMenu: function () {
            $('.menu-hover-links__item').hover(function () {
                $(this)
                    .parent()
                    .parent()
                    .find('.menu-hover-section .menu-hover-category__item')
                    .empty()
                    .html($('.menu-hover-section-' + $(this).data('key')).html());
            });
        },

        fire: function () {
            Titan.Ui.select2();
            Titan.Ui.datepicker();
            Titan.Ui.mask();
            Titan.Ui.headerMenu();
        }
    },

    /**
     * Run process
     *
     * @constructor
     */
    Fire: function () {
        Titan.Config = TitanConfig;

        Titan.Ui.fire();
        Titan.Search.fire();
    }
};


$(document).ready(function () {
    Titan.Fire();
});
