function cLog(log) {
    console.log(log)
}

$(document).ready(function () {

    $("input").on("change.autofill", function () {
        $(".js-input-placeholder").addClass("active");
    }).click(function() {
        $(this).unbind("change.autofill");
    });




    $('.js-toggle-menu').click(function (e) {
        e.preventDefault();

        if($('.js-login').hasClass('active') || $('.js-user-menu').hasClass('active')) {
            $('.js-login').removeClass('active');
            $('.js-user-menu').removeClass('active');
            $('html').removeClass('menu-show');

        } else {
            $('.js-mobile-menu').toggleClass('active');
            $('html').toggleClass('menu-show');
            $('.header').toggleClass('active');
        }

    });

    $('.js-header-login-btn').click(function (e) {

        if($('.js-mobile-menu').hasClass('active')) {
            $('.js-mobile-menu').removeClass('active');
            $('.header').removeClass('active');
            setTimeout(function() {
                $('.js-login').toggleClass('active');
            }, 300);

        } else {
            $('.js-login').toggleClass('active');
            $('html').toggleClass('menu-show');
        }
    });

    $('.js-user-menu-btn').click(function (e) {
        e.preventDefault();

        if($('.js-mobile-menu').hasClass('active')) {
            $('.js-mobile-menu').removeClass('active');
            $('.header').removeClass('active');
            setTimeout(function() {
                $('.js-user-menu').toggleClass('active');
            }, 300);

        } else {
            $('.js-user-menu').toggleClass('active');
            $('html').toggleClass('menu-show');
        }
    });

    $('.js-menu-dropdown-btn').click(function (e) {
        e.preventDefault();
        var target = $(this).parents('.js-menu-dropdown');

        // target.find('.js-menu-dropdown-list').slideDown();

        if (target.hasClass('active')) {
            target.removeClass('active').find('.js-menu-dropdown-list').slideUp();
        }

        else {
            $('.js-menu-dropdown').removeClass('active').find('.js-menu-dropdown-list').slideUp();
            target.addClass('active').find('.js-menu-dropdown-list').slideDown();
        }

    });

    $('.js-submenu-btn').click(function (e) {
        e.preventDefault();
        var target = $(this).parents('.js-submenu')

        if (target.hasClass('active')) {
            target.removeClass('active').find('.js-submenu-list').slideUp();
        }

        else {
            $('.js-submenu').removeClass('active').find('.js-submenu-list').slideUp();
            target.addClass('active').find('.js-submenu-list').slideDown();
        }

    })

    var menuHover = $('.js-menu-hover');

    menuHover.on('mouseover', function () {
        var x = $('.js-menu-hover-wrp').position().left;
        var y = $('.js-menu-hover-inner').offset().top - $('.header__wrp').offset().top;
        var menuWidth = $(this).find('.js-menu-hover-inner').outerWidth();
        var menuHeight = $(this).find('.js-menu-hover-inner').outerHeight();

        var caretX = $(this).position().left + $(this).outerWidth() / 2;

        $('.js-overlay').addClass('active');

        $('.js-menu-hover-caret').addClass('active').css({
            transform: 'translateX(' + caretX + 'px)',
        });

        $('.js-menu-hover-bg').addClass('active').css({
            transform: 'translateX(' + x + 'px) translateY(' + y + 'px)',
            width: menuWidth,
            height: menuHeight
        });
    }).on('mouseout',function () {
        $('.js-menu-hover-caret').removeClass('active');
        $('.js-menu-hover-bg').removeClass('active');
        $('.js-overlay').removeClass('active');
    });

    // menuHover.each(function (index, item) {
    //
    // });

    $('.js-faq-filter-btn').click(function (e) {
        e.preventDefault();
        var target = $(this).data('filter');

        $('.js-faq-filter-btn').removeClass('active');
        $(this).addClass('active');

        $('.js-faq-filter-item:not(.'+ target +')').addClass('hide-item');

        setTimeout(function() {
            $('.js-faq-filter-item:not(.'+ target +')').hide();
        }, 500);

        $('.js-faq-filter-item.'+target).show();

        setTimeout(function() {
            $('.js-faq-filter-item.'+target).removeClass('hide-item')
        }, 100);

        if($('.subpage-link__list').hasClass('opened')) {
            // $('.subpage-link__li').removeClass('active');
            // $('.subpage-link__li-mobile').slideUp();
            // $(this).parent().addClass('active');
            // $('.subpage-link__li:not(.active)').slideUp();
        }

        // if($('.subpage-link__list').hasClass('opened')) {
        //     $('.subpage-link__list').removeClass('opened');
        //     $('.subpage-link__li').removeClass('active');
        //     $('.subpage-link__li:not(.active)').slideUp();
        //     $(this).parent().addClass('active')
        // }

    });

    $('.subpage-link__li.active').click(function (e) {
        e.preventDefault();
    });

    $('.subpage-link__li').click(function (e) {

        if($(this).parents('.subpage-link').hasClass('subpage-link--filter') ) {
            e.preventDefault();
        }

        if($(window).width() < 577) {

            if($(this).hasClass('active')) {
                $(this).parents('.subpage-link__list').toggleClass('opened');
                $('.subpage-link__li:not(.active)').slideToggle();
            } else {
                $(this).parents('.subpage-link__list').removeClass('opened').addClass('selected');
                $('.subpage-link__li').removeClass('active');
                $(this).addClass('active');
                $('.subpage-link__li:not(.active)').slideUp();
            }
        }
    });

    $('.card__list-link.active').click(function (e) {
        if($(window).width() < 769) {
            e.preventDefault();
            $('.card__list').toggleClass('active');
            $('.card__list-link:not(.active)').slideToggle()
        }
    });

    // search autocomplete

    $('.js-search-input').on('focus', function (e) {
        $('.js-overlay').addClass('active');
        $('.js-search').addClass('active');
        $('.js-search-category').removeClass('active');
    }).on('focusout', function () {
        if(!$(this).val()=="" || !$(this).val()==" ") {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });

    $('.js-search-category-btn').on('click', function (e) {
        $('.js-overlay').addClass('active');
        $('.js-search-category').addClass('active');
        $('.js-search').removeClass('active').addClass('opened');
    });

    function closeModals() {
        $('.js-overlay').removeClass('active');
        $('.js-search').removeClass('active');
        $('.js-search').removeClass('opened');
        $('.js-modal').fadeOut().removeClass('active');
        $('.js-search-category').removeClass('active');
        $('.js-tab-mobile').removeClass('active');

        if($('.js-youtube-iframe').length) {
            $('.js-youtube-iframe').each(function (index, item) {
                var check = $(this).parents('.js-youtube-wrp').find('.js-youtube-iframe-overlay');

                if(check.hasClass('hide-overlay')) {
                    var currentSrc = $(this).data('src');
                    $(this).attr('src', currentSrc);
                    $('.js-youtube-iframe-overlay').removeClass('hide-overlay');
                }
            });
        }
    }

    $('.js-overlay').click(function (e) {
        e.preventDefault();
        closeModals();
    });

    $('.js-modal-close').click(function (e) {
        e.preventDefault();
        closeModals();
    });

    $(".js-user-profile").on("change", function() {
        var files = !!this.files ? this.files : [];
        if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

        if (/^image/.test(files[0].type)) { // only image file
            var reader = new FileReader(); // instance of the FileReader
            reader.readAsDataURL(files[0]); // read the local file

            reader.onloadend = function() { // set image data as background of div
                // $("#imagePreview").css("background-image", "url(" + this.result + ")");
                // console.log(this.result);
                $(".js-user-profile-img").attr('src', this.result)
            }
        }
    });

    $('.js-play-video').on('click', function(ev) {
        ev.preventDefault();
        var src = $(this).parents('.js-youtube-wrp').find(".js-youtube-iframe")[0].src;
        $(this).parents('.js-youtube-wrp').find(".js-youtube-iframe").attr('data-src', src);
        $(this).parents('.js-youtube-wrp').find(".js-youtube-iframe")[0].src += "&autoplay=1";
        $(this).parents('.js-youtube-wrp').find('.js-youtube-iframe-overlay').addClass('hide-overlay')
    });

    $('.js-modal-btn').click(function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        $('.js-modal-target').hide();
        $('.js-modal-target#' + target).show();
        $('.js-overlay').addClass('active');
        $('.js-modal').fadeIn().addClass('active');
        // $('#programModal').fadeIn().addClass('active');
    });


    $('.js-comments-more').click(function (e) {
        e.preventDefault();
        var target = $(this).parents('.js-comments').find('.js-comments-hide');
        target.slideDown(300).addClass('active');
        $(this).slideUp(300);
    });

    $('.js-comments-less').click(function (e) {
        e.preventDefault();
        var target = $(this).parents('.js-comments-hide');
        target.slideUp(300).removeClass('active');
        $('.js-comments-more').slideDown(300);

        setTimeout(function() {
        }, 290);
    });

    $('.js-comments-txt').each(function (index, item) {
        var text = $(this).text().trim();
        var charLimit = 160;

        cLog(text.length);

        if(text.length > charLimit) {
            var shortText = text.trim().substr(0, charLimit);

            $(this).html(shortText + '... <button class="comments-read-more js-read-more">Devamını Oku</button>').attr('data-text', text);
        }
        // console.log(shortText)
    });

    $('.js-comment-btn').click(function (e) {
        e.preventDefault();
        $('.js-comment-form').addClass('active');
    });

    $('.js-read-more').click(function (e) {
        e.preventDefault();
        var text = $(this).parent().data('text');
        $(this).parent().html(text)

    });

    // document.onkeydown = function(evt) {
    //     evt = evt || window.event;
    //     // esc
    //     if (evt.keyCode == 27) {
    //         $(this).removeClass('active');
    //         $('.js-search').removeClass('active');
    //     }
    // };

    // position tab

    $('.js-position-tab').click(function (e) {
        e.preventDefault();
        var target = $(this).data('target');

        $('.js-position-tab').removeClass('active');
        $('.js-position-tab-target').removeClass('active');

        $(this).addClass('active');
        $('.js-position-tab-target#' + target).addClass('active');
    });

    // $('.js-programs-mobile.active').click(function (e) {
    //     e.preventDefault();
    //     // $('.js-programs-mobile:not(.active)').slideToggle();
    //     // $('.js-programs-mobile').removeClass('active');
    //     // $(this).addClass('active');
    //     // cLog($(this).html());
    // });

    $('.js-programs-mobile').click(function (e) {
        e.preventDefault();
        if($(this).hasClass('active')) {
            $(this).parents('.programs-tab-mobile').toggleClass('opened');
            $('.js-programs-mobile:not(.active)').slideToggle();
        } else {
            $('.js-programs-mobile').removeClass('active');
            $(this).parents('.programs-tab-mobile').removeClass('opened');
            $(this).addClass('active');
            $('.js-programs-mobile:not(.active)').slideUp();

            var target = $(this).data('target');
            $('.js-position-tab-target').removeClass('active');
            $('.js-position-tab-target#' + target).addClass('active');

        }
    });


    // input validation

    $('.js-input').on('focusout', function (e) {
        // e.preventDefault();
        if(!$(this).val()=="" || !$(this).val()==" ") {
            $(this).parents('.js-input-container').find('.js-input-placeholder').addClass('active');
        } else {
            $(this).parents('.js-input-container').find('.js-input-placeholder').removeClass('active');
        }
    }).each(function (i, item) {
        $(this).on('focusout', function (e) {
            if($(this).val()=="" || $(this).val()==" " || $(this).val()==null) {
                // $(this).parents('.contact-form__item').find('.error-message').show()
                // $(this).addClass('error')
                $(this).parents('.js-input-container').addClass('has-error').find('.js-form-error').addClass('active');
            } else {
                $(this).parents('.js-input-container').removeClass('has-error').find('.js-form-error').removeClass('active');
            }


        })
    }).on('change keyup', function (e) {
        e.preventDefault();
        $(this).parents('.js-input-container').removeClass('has-error').find('.js-form-error').removeClass('active');
    });

    $('.js-form').on('submit', function (e) {
        e.preventDefault();

        var input = $(this).find('.js-input'),
            inputVal = input.val(),
            count = input.length,
            validCount = 0;

        input.each(function (e) {
            if($(this).val()=="" || $(this).val()==" " || $(this).val()==null)  {
                $(this).parents('.js-input-container').addClass('has-error').find('.js-form-error').addClass('active');
            }
            else (
                validCount++
            );

            return validCount
        });

        console.log(validCount);



        if(validCount==count) {
            console.log('succes');

            if($(this).hasClass('js-call-form')) {

                $('.call-form__wrp').addClass('form-send')

            } else if($('#formResult').length)  {
                $('.js-modal-target').hide();
                $('.js-modal-target#formResult').show();
                $('.js-overlay').addClass('active');
                $('.js-modal').fadeIn().addClass('active');
            }

            input.val('');
            $('.js-input-placeholder').removeClass('active');

            return true
            // return false
        }
        else {
            console.log('form error');
            return false
        }
    });

    $('.call-form-result').click(function (e) {
        e.preventDefault();
        $('.call-form__wrp').removeClass('form-send')
    });

    $('.js-hide-element').click(function (e) {
        e.preventDefault();
        var target = $(this).data('target');

        $('#'+target).addClass('element-hide');

        setTimeout(function() {
            $('#'+target).slideUp();
        }, 50);


    });

    $('.js-select-search').select2().on('select2:open', function (e) {
        // console.log('as');
        // $(this).parents('.js-input-container').find('.js-input-placeholder').addClass('active');
    }).on('select2:close', function (e) {
        var testData = $(this).val();
        console.log(testData);

        if($(this).val()=="" || $(this).val()==" " || $(this).val()==null) {
            $(this).parents('.js-input-container').find('.js-input-placeholder').removeClass('active');
            $(this).parents('.js-input-container').addClass('has-error').find('.js-form-error').addClass('active');
        } else {
            $(this).parents('.js-input-container').find('.js-input-placeholder').addClass('active');
        }
    });

    $(".js-select").select2({
        minimumResultsForSearch: Infinity,
      });

    $('.js-input-container .js-select').select2({
        minimumResultsForSearch: -1,
        ////////
        placeholder: "",
        allowClear: true,
        width: '100%'
    }).on('select2:open', function (e) {
        // console.log('as');
        // $(this).parents('.js-input-container').find('.js-input-placeholder').addClass('active');
    }).on('select2:close', function (e) {
        var testData = $(this).val();
        // console.log(testData);

        if($(this).val()=="" || $(this).val()==" " || $(this).val()==null) {
            $(this).parents('.js-input-container').find('.js-input-placeholder').removeClass('active');
            $(this).parents('.js-input-container').addClass('has-error').find('.js-form-error').addClass('active');
        } else {
            $(this).parents('.js-input-container').find('.js-input-placeholder').addClass('active');
        }
    });

    $('.js-datepicker').datepicker({
        autoHide: true,
        days: ['Pazar','Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        daysShort: ['Pzr', 'Pzt', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cmt'],
        daysMin: ['Pz', 'Pt', 'Sa', 'Çr', 'Pr', 'Cu', 'Ct'],
        months: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        monthsShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kası', 'Ara'],
        weekStart: 1,

    }).on('show.datepicker', function (e) {
        console.log('show');
        // $(this).parents('.js-input-container').find('.js-input-placeholder').addClass('active');
    }).on('change', function (e) {
        console.log('pick');
        if($(this).val()=="" || $(this).val()==" " || $(this).val()==null) {
            $(this).parents('.js-input-container').find('.js-input-placeholder').removeClass('active');
            $(this).parents('.js-input-container').addClass('has-error').find('.js-form-error').addClass('active');
        } else {
            $(this).parents('.js-input-container').find('.js-input-placeholder').addClass('active');
        }
    });





    // market slider tab

    var categoryTab = $('.category-tab');
    var categoryTabWrp = $('.category-tab__wrp');

    if(categoryTabWrp.width() > $(window).width() - 61 ) {
        categoryTab.addClass('tab-mobile js-tab-mobile');
    }

    $('.js-category-tab-title').click(function (e) {
        e.preventDefault();
        $(this).parents('.js-tab-mobile').addClass('active');
        $('.js-overlay').addClass('active');
    });

    var sliderActive = $('.js-category-tab.active');

    if(sliderActive.length) {
        var sliderX = sliderActive.position().left;
        var sliderWidth = sliderActive.outerWidth() + 10;
        var sliderHeight = sliderActive.outerHeight();

        var sliderColor = sliderActive.data('color');
        $('.js-category-tab-bg').css({
            transform: 'translateX(' + sliderX + 'px)',
            width: sliderWidth,
            height: sliderHeight
        });

        $('.category-circle').css('color', sliderColor);
        $('.js-category-item').css('color', sliderColor);

    }


    $('.js-category-tab').click(function (e) {
        e.preventDefault();
        var x = $(this).position().left;
        var bgWidth = $(this).outerWidth() + 10;
        var bgHeight = $(this).outerHeight();
        var color = $(this).data('color');
        var target = $(this).data('target');

        $('.js-category-tab').removeClass('active');
        $('.js-category-tab-target').removeClass('active');

        $(this).addClass('active');

        $('.js-category-tab-target#' + target).addClass('active');

        $('.js-category-item').css('color', color);

        if(!categoryTab.hasClass('js-tab-mobile')) {
            $('.js-category-tab-bg').css({
                transform: 'translateX(' + x + 'px)',
                width: bgWidth,
                height: bgHeight
            });


            setTimeout(function() {
                $('.category-circle').removeClass('passive').css('color', color);
            }, 250);
            $('.category-circle').addClass('passive');


        } else {
            var text = $(this).text().trim();
            $('.js-category-tab-title').text(text);
            // $('.category-tab__wrp').slideUp();
            $('.js-tab-mobile').removeClass('active');
            $('.js-overlay').removeClass('active');

        }

    });


    function count(event) {

        var countInput = $('.js-count-input').val();

        if(event == "plus") {
            countInput ++;

            $('.js-count-input').val(countInput)
        }

        else if(event == "minus") {
            countInput --;



            if(countInput== 1 || countInput < 1) {
                $('.js-count-input').val(1);
            }

            else {
                $('.js-count-input').val(countInput);
            }

        }
    }

    $('.js-count-btn').click(function (e) {
        e.preventDefault();
        var event = $(this).data('event');
        count(event)
    });

    document.onkeydown = function(evt) {
        evt = evt || window.event;
        // esc
        if (evt.keyCode == 27) {
            $('.js-overlay').removeClass('active');
            $('.js-search').removeClass('active');
            $('.js-modal').removeClass('active');
            $('.js-search-category').removeClass('active');
        }
    };
    // $(".js-block-toggle#" + target).addClass("active");

    $(".js-block-tab").click(function (e) {
        e.preventDefault();
        var target = $(this).data("target");
        $(".js-block-tab").removeClass("active");
        $(".js-block-toggle").removeClass("active");
        $(this).addClass("active");
        $(".js-block-toggle#" + target).addClass("active");
    });


    $(".js-block-hov-tab").hover(function (e) {
        e.preventDefault();
        var target = $(this).data("target");
        var parent = $(this).parents('.js-menu-hover-wrp')
        parent.find(".js-block-hov-tab").removeClass("active");
        parent.find(".js-block-hov-toggle").removeClass("active");
        parent.find(this).addClass("active");
        parent.find(".js-block-hov-toggle#" + target).addClass("active");
    });


    cLog('page loaded js');


    $(".js-mascot-mouse-events").mouseover(function(){
        $(".footer-mascot__head").addClass("active");
    });
    $(".js-mascot-mouse-events").mouseout(function(){
        $(".footer-mascot__head").removeClass("active");
    });

    $('.js-category-filter').on('select2:select', function (e) {
        console.log($(this).val());
        var data = $(this).val()
        var parent = $('.js-program-chg.active')
        parent.find('.js-category').hide();
        // var itemArr = []
        parent.find('.js-category.' + data).show()
        // parent.find('.js-category').each(function (item, index) {
        //     var categoryArr = $(this).data('category').split(',')
        //     console.log(categoryArr)
        // })
    });

});





